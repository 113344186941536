export const colors = {
  white: "#fff",
  darkGray: "#1F2123",
  mediumGray: "#666",
  lightGray: "#a0a0a0",
  black: "#000",
  blue: "#37608a",
  purple: "#3b39bd",
}

export const fonts = {
  sans: "Barlow, Helvetica, sans-serif",
}

export default {
  ...colors,
  ...fonts,
}
