import React from "react"
import ToggleButton from "components/ToggleButton"
import useApp from "hooks/useApp"
import { Link } from "gatsby"

const Header = () => (
  <header
    css={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 16,
      position: "sticky",
      top: 0,
      zIndex: 5,
      background: "hsla(210, 6%, 7%, 0.8)",
      boxShadow: "0 4px 8px 1px rgba(0,0,0,0.2)",
      backdropFilter: "blur(12px)",
    }}
  >
    <h1
      css={{
        textTransform: "uppercase",
        fontWeight: 600,
        userSelect: "none",
        letterSpacing: "0.1em",
      }}
    >
      <Link
        to="/"
        css={{
          textDecoration: "none",
          transition: "letter-spacing 4s",
          ":hover": { letterSpacing: "0.4em" },
        }}
      >
        Into Obscurity
      </Link>
    </h1>

    <div css={{ display: "flex", justifyContent: "flex-end" }}>
      <NSFWToggle />
    </div>
  </header>
)

export default Header

const NSFWToggle = () => {
  const app = useApp()

  return (
    <ToggleButton
      onClick={app.toggleNsfw}
      isActive={!!app.nsfw}
      label="NSFW"
      id="header-nsfw-toggle"
    />
  )
}
