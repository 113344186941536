import { css } from "@emotion/core"
import normalize from "emotion-normalize"
import { colors, fonts } from "styles"

export default css`
  ${normalize}

  /*
  {stratford}
  :root {
    $\{desktop}

    @media (max-width: 1080px) {
      $\{tablet}
    }

    @media (max-width: 730px) {
      $\{mobile}
    }
  }*/

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html {
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern" 1, "pnum" on, "lnum" on;
    font-kerning: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${fonts.sans};
    background: ${colors.darkGray};
    color: ${colors.white};
    font-size: 16px;
    word-break: break-word;
    line-height: 1.5;

    @media (max-width: 500px) {
      font-size: 15px;
    }
    @media (max-width: 370px) {
      font-size: 14px;
    }
  }

  body {
    box-sizing: border-box;
  }

  a {
    /*text-decoration: none;*/
    cursor: pointer;
    color: inherit;
  }

  /*p > a {
    text-decoration: underline;
  }*/

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
    margin-top: 0;
    margin-bottom: 0.2em;
  }

  img {
    max-width: 100%;
  }
`
