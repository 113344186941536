import React from "react"
import PropTypes from "prop-types"
import styles from "styles"

const ToggleButton = ({
  id,
  onClick,
  isActive,
  label,
  className,
  buttonStyles,
}) => (
  <div
    css={{
      display: "flex",
      alignItems: "center",
    }}
    className={className}
  >
    {label && (
      <label
        htmlFor={id}
        css={{
          fontSize: 12,
          cursor: "pointer",
          userSelect: "none",
          marginRight: "0.5em",
          color: styles.mediumGray,
        }}
      >
        {label}
      </label>
    )}

    <button
      id={id}
      onClick={onClick}
      css={{
        background: isActive ? styles.purple : "#eee",
        borderRadius: 30,
        border: 0,
        position: "relative",
        height: 18,
        width: 40,
        outline: 0,
        cursor: "pointer",
        transition: "background 300ms cubic-bezier(0.83, 0.21, 0.25, 0.98)",
        "&:hover": {
          background: isActive ? styles.purple : "#bbb",
        },
        "&:after": {
          content: '""',
          width: 16,
          height: 16,
          background: "#fff",
          border: "1px solid #eee",
          borderRadius: 16,
          position: "absolute",
          top: 1,
          left: isActive ? "calc(100% - 17px)" : 1,
          transition: "left 300ms cubic-bezier(0.83, 0.21, 0.25, 0.98)",
        },
        ...buttonStyles,
      }}
    />
  </div>
)

export default ToggleButton

ToggleButton.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  buttonStyles: PropTypes.object,
}
