import React from "react"
import { colors } from "styles"

const Footer = () => (
  <footer
    css={{
      padding: 24,
      textAlign: "center",
      color: colors.mediumGray,
      fontSize: 10,
      letterSpacing: "0.025em",
      marginTop: 100,
      textTransform: "uppercase",
    }}
  >
    © 2020 Corey Ward — Don’t steal my photos.
  </footer>
)

export default Footer
