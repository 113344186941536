// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-photo-page-jsx": () => import("./../src/templates/PhotoPage.jsx" /* webpackChunkName: "component---src-templates-photo-page-jsx" */),
  "component---src-templates-person-page-jsx": () => import("./../src/templates/PersonPage.jsx" /* webpackChunkName: "component---src-templates-person-page-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

