import React from "react"
import PropTypes from "prop-types"
import { Global } from "@emotion/core"
import AppContext from "context/App"
import baseStyles from "styles/base"
import Helmet from "react-helmet"
import Header from "components/Header"
import Footer from "components/Footer"
import useStorage from "hooks/useStorage"
import trackEvent from "lib/track-event"

const NSFW = "io_safelevel"

const App = ({ children }) => {
  const [nsfw, setNsfw] = useStorage(NSFW, { bool: true })

  return (
    <>
      <Global styles={baseStyles} />
      <Helmet title="Into Obscurity | Photography by Corey Ward">
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#1f2123" />
      </Helmet>

      <AppContext.Provider
        value={{
          nsfw,
          toggleNsfw: () => {
            setNsfw(!nsfw)
            trackEvent("NSFW Toggled", nsfw ? "Off" : "On")
          },
        }}
      >
        <Header />
        {children}
        <Footer />
      </AppContext.Provider>
    </>
  )
}

export default App

App.propTypes = {
  children: PropTypes.node.isRequired,
}
